@charset "utf-8";
//
//サイトで使う変数をすべてここで定義する
//------------------------------------

// site color
//====================================
$black: #000;
$gray: #353536;
$blue: #3aabd2;
$sky: #dcf5ff;
