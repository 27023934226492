@charset "UTF-8";
/*=============================================================
z-index
	~1000	used by slick
	2000~	gnav
=============================================================*/
/*=============================================================
 01_setting
=============================================================*/
/*=============================================================
 02_base
=============================================================*/
/*!  02_base
================================================ */
html {  overflow: auto; }

body {  min-width: 320px; color: #000; font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; font-size: 1.3rem; font-weight: 400; line-height: 1.92; overflow: hidden; }

@media all and (min-width: 600px) {  body {  min-width: 1000px; } }

div, span, h1, h2, h3, h4, h5, h6, p, address, em, small, strong, sub, sup, b, i, dl, dt, dd, ol, ul, li, form, label, input, select, textarea, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, footer, header, nav, section, time {  font: inherit; }

a {  color: #000; text-decoration: none; }

@media all and (min-width: 600px) {  a:hover {  text-decoration: underline; }
  a.alpha {  transition: opacity 0.3s linear; }
  a.alpha:hover {  opacity: .8; text-decoration: none; } }

img {  width: 100%; height: auto; }

.cf:after {  display: block; clear: both; content: ""; }

.viewPc {  display: none; }

@media all and (min-width: 600px) {  .viewSp {  display: none; }
  .viewPc {  display: block; } }

/*! media判定用
---------------------------------------- */
.mediaQuery {  display: none; font-family: "sp"; }

@media all and (min-width: 600px) {  .mediaQuery {  font-family: "pc"; } }

/* layout
--------------------------------------- */
/*=============================================================
 03_layout
=============================================================*/
/*! #gHeader
================================================ */
#gHeader {  position: relative; background: #fff; z-index: 2001; }

#gHeader .btnSearch {  position: relative; float: right; width: 50px; height: 50px; background: #3aabd2; cursor: pointer; }

#gHeader .btnSearch span {  display: block; position: absolute; left: 13px; content: ''; width: 24px; transition: all 0.3s linear; }

#gHeader .btnSearch span:nth-child(1) {  top: 24px; height: 2px; background-color: #fff; opacity: 0; }

#gHeader .btnSearch span:nth-child(2) {  top: 13px; left: 13px; height: 24px; background-color: transparent; overflow: hidden; }

#gHeader .btnSearch span:nth-child(2) img {  position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 24px; height: 24px; margin: auto; }

#gHeader .btnSearch.open span:nth-child(1) {  opacity: 1; transform: rotate(-315deg); }

#gHeader .btnSearch.open span:nth-child(2) {  top: 24px; height: 2px; background-color: #fff; transform: rotate(-405deg); }

#gHeader .btnGnav {  position: relative; float: right; width: 50px; height: 50px; margin-left: 1px; background: #353536; cursor: pointer; }

#gHeader .btnGnav span {  position: absolute; left: 13px; width: 24px; height: 2px; background: #fff; transition: all 0.3s linear; }

#gHeader .btnGnav span:nth-child(1) {  top: 11px; }

#gHeader .btnGnav span:nth-child(2) {  top: 17px; }

#gHeader .btnGnav span:nth-child(3) {  top: 23px; }

#gHeader .btnGnav .txt {  display: block; position: absolute; top: 31px; left: 11px; width: 28px; }

#gHeader .btnGnav.open span:nth-child(1) {  top: 17px; transform: rotate(-315deg); }

#gHeader .btnGnav.open span:nth-child(2) {  opacity: 0; transform: rotate(-360deg); }

#gHeader .btnGnav.open span:nth-child(3) {  top: 17px; transform: rotate(-405deg); }

/*! #hdMeta
-------------------------------------- */
#hdMeta {  float: left; width: 210px; }

#hdMeta a {  position: relative; display: block; padding: 7px 0 0 10px; }

#hdMeta a .logo {  overflow: hidden; }

#hdMeta a .logo .mark {  display: block; float: left; width: 29px; margin-right: 4px; }

#hdMeta a .logo .name {  display: block; float: left; width: 162px; }

#hdMeta a .txt {  position: absolute; top: 26px; left: 43px; width: 160px; padding-top: 2px; border-top: 1px solid #000; font-size: 1rem; text-align: center; line-height: 1.1; }

@media all and (min-width: 600px) {  #hdMeta {  width: 516px; height: 140px; }
  #hdMeta a {  padding: 15px 0 0 40px; text-decoration: none; }
  #hdMeta a .logo .mark {  width: 66px; margin-right: 8px; }
  #hdMeta a .logo .name {  width: 366px; padding-top: 39px; }
  #hdMeta a .txt {  position: static; width: 435px; margin-top: 7px; padding-top: 7px; font-size: 2rem; text-align: right; } }

/*! #hdSearch
-------------------------------------- */
#hdSearch {  display: none; position: absolute; top: 50px; left: 0; width: 100%; padding: 35px 25px 25px; background: #3aabd2; box-sizing: border-box; z-index: 2001; }

#hdSearch .tit {  display: block; margin-bottom: 10px; color: #fff; font-size: 1.5rem; font-weight: 700; line-height: 1.6; }

#hdSearch .inputWrap {  position: relative; }

#hdSearch .inputWrap input[type="search"] {  display: block; width: 100%; height: 45px; padding: 10px 55px 10px 10px; background: #fff; box-sizing: border-box; }

#hdSearch .inputWrap input[type="submit"] {  position: absolute; top: 0; right: 10px; width: 45px; height: 45px; background: url(../img/common/ico_search01_blue.png) no-repeat 50% 50%; background-size: 25px; text-indent: 200%; white-space: nowrap; overflow: hidden; }

@media all and (min-width: 600px) {  #hdSearch {  display: block; position: absolute; top: 30px; left: auto; right: 40px; width: 466px; padding: 12px 14px; }
  #hdSearch .tit {  float: left; width: 127px; margin-bottom: 0; padding-top: 5px; }
  #hdSearch .inputWrap {  float: right; width: 260px; padding-right: 51px; }
  #hdSearch .inputWrap input[type="search"] {  display: block; width: 260px; height: 36px; padding: 10px; border-radius: 4px; }
  #hdSearch .inputWrap input[type="submit"] {  position: absolute; top: 0; right: 0; width: 36px; height: 36px; border: 2px solid #fff; border-radius: 4px; background-image: url(../img/common/ico_search01_white.png); background-size: 27px; } }

/*! #hdContact
-------------------------------------- */
@media all and (min-width: 600px) {  #hdContact {  position: absolute; top: 108px; right: 40px; }
  #hdContact a {  display: block; padding-left: 42px; background: url(../../img/common/ico_mail01_blue.png) no-repeat 0 50%; color: #3aabd2; font-size: 2rem; line-height: 1.6; text-decoration: underline; }
  #hdContact a:hover {  text-decoration: none; } }

/*! #gNav
================================================ */
#gNav {  display: none; position: absolute; top: 50px; left: 0; width: 100%; padding: 25px 25px 35px; background: #dcf5ff; box-sizing: border-box; z-index: 2001; }

#gNav .listMain .item {  margin-bottom: 14px; padding-bottom: 14px; border-bottom: 1px solid #3aabd2; }

#gNav .listMain .item .parent {  position: relative; display: block; padding: 9px 10px 8px; color: #000; font-size: 18px; font-weight: 700; line-height: 1.5; }

#gNav .listMain .item .parent::after {  position: absolute; top: 50%; right: 10px; width: 12px; height: 15px; margin-top: -7px; content: ''; background: url(../img/common/ico_arrow01_blue.png) no-repeat; background-size: cover; transform: rotate(90deg); }

#gNav .listMain .item .itemChild a {  position: relative; display: block; padding: 2px 35px; color: #000; font-size: 1.5rem; font-weight: 700; line-height: 1.6; }

#gNav .listMain .item .itemChild a::before {  position: absolute; top: 7px; left: 20px; width: 10px; height: 14px; content: ''; background: url(../img/common/ico_arrow01_black.png) no-repeat; background-size: cover; }

#gNav .listSub {  margin-bottom: 32px; }

#gNav .listSub .item a {  position: relative; display: block; padding: 6px 10px; font-size: 1.5rem; line-height: 1.6; }

#gNav .listSub .item a::after {  position: absolute; top: 50%; right: 10px; width: 12px; height: 15px; margin-top: -7px; content: ''; background: url(../img/common/ico_arrow01_blue.png) no-repeat; background-size: cover; }

#gNav .btnClose {  width: 230px; height: 45px; margin: 0 auto; padding: 10px; border: 1px solid #3aabd2; box-sizing: border-box; color: #3aabd2; font-size: 1.6rem; line-height: 1.5; text-align: center; cursor: pointer; }

@media all and (min-width: 600px) {  #gNav {  display: block; position: static; width: 100%; padding: 0; background: #fff; border-bottom: 2px solid #3aabd2; }
  #gNav .listMain {  width: 960px; margin: 0 auto; padding: 0 20px; }
  #gNav .listMain .item {  position: relative; float: left; width: 230px; margin-bottom: 0; padding-bottom: 0; border-bottom: 0; }
  #gNav .listMain .item + .item {  margin-left: 135px; }
  #gNav .listMain .item .parent {  position: relative; padding: 15px 0; font-size: 2.5rem; text-align: center; line-height: 1.6; transition: all 0.3s linear; cursor: pointer; }
  #gNav .listMain .item .parent::after {  display: none; }
  #gNav .listMain .item .child {  position: absolute; top: 57px; left: 0; width: 100%; padding: 10px 0; background: rgba(220, 255, 255, 0.9); z-index: 2000; transition: all 0.3s linear; visibility: hidden; opacity: 0; }
  #gNav .listMain .item .child::before {  position: absolute; top: -15px; left: 50%; width: 0; height: 0; content: ''; margin-left: -9px; border-style: solid; border-width: 0 9px 15px 9px; border-color: transparent transparent #dcf5ff; }
  #gNav .listMain .item .itemChild a {  position: relative; padding: 13px 0; font-size: 2rem; text-align: center; line-height: 1.5; transition: all 0.3s linear; }
  #gNav .listMain .item .itemChild a:hover {  color: #3aabd2; text-decoration: underline; }
  #gNav .listMain .item .itemChild a::before {  display: none; }
  #gNav .listMain .item .parent.open {  background: #3aabd2; color: #fff; }
  #gNav .listMain .item .parent.open + .child {  top: 72px; visibility: visible; opacity: 1; } }

/*! #overlay
-------------------------------------- */
#overlay {  display: none; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(53, 53, 54, 0.55); z-index: 2000; }

/*! #mainImg 下層ページ
================================================ */
/*! #mainImg
================================================ */
/*! #breadcrumb
================================================ */
/*! #sidebar
================================================ */
/*! #gFooter
================================================ */
#gFooter .footerIn {  padding-right: 15px; padding-left: 15px; }

@media all and (min-width: 600px) {  #gFooter .footerIn {  width: 960px; margin: 0 auto; padding-right: 20px; padding-left: 20px; } }

/*! #ftLink
-------------------------------------- */
#ftLink {  margin-top: -15px; padding-top: 13px; padding-bottom: 40px; border-bottom: 2px solid #3aabd2; }

#ftLink .bnr {  margin-top: 15px; }

#ftLink .bnr a {  display: block; border: 2px solid #a0a0a0; }

@media all and (min-width: 600px) {  #ftLink {  margin-top: 0; padding: 15px 0 0; text-align: center; letter-spacing: -.5em; }
  #ftLink .bnr {  display: inline-block; width: 360px; margin: 0 35px 70px; letter-spacing: normal; }
  #ftLink .bnr a {  transition: all 0.3s linear; }
  #ftLink .bnr a:hover {  border-color: #3aabd2; } }

/*! #ftNav
-------------------------------------- */
#ftNav {  position: relative; padding-top: 35px; }

#ftNav .pagetop {  position: absolute; top: -23px; left: 50%; width: 46px; height: 46px; margin-left: -23px; z-index: 10; }

#ftNav .pagetop a {  position: relative; display: block; width: 46px; height: 46px; padding-top: 18px; border-radius: 23px; background: #3aabd2; color: #fff; font-size: 1rem; text-align: center; box-sizing: border-box; }

#ftNav .pagetop a::before {  position: absolute; top: 8px; left: 50%; width: 8px; height: 10px; content: ''; margin-left: -4px; background: url(../img/common/ico_arrow01_white.png) no-repeat 50% 50%; background-size: cover; transform: rotate(-90deg); }

#ftNav .list {  padding-top: 47px; background: url(../img/common/footer/logo01.png) no-repeat 50% 0; background-size: 38px; }

#ftNav .list .item a {  display: block; color: #3aabd2; font-size: 1.5rem; font-weight: 700; text-align: center; }

@media all and (min-width: 600px) {  #ftNav {  padding-top: 0; }
  #ftNav .pagetop {  position: absolute; top: -51px; right: -76px; left: auto; width: 64px; height: 64px; margin-left: 0; }
  #ftNav .pagetop a {  width: 64px; height: 64px; padding-top: 26px; border-radius: 32px; font-size: 1.3rem; }
  #ftNav .pagetop a::before {  top: 12px; width: 11px; height: 14px; margin-left: -7px; transition: all 0.3s linear; }
  #ftNav .pagetop a:hover {  text-decoration: none; }
  #ftNav .pagetop a:hover::before {  top: 9px; }
  #ftNav .list {  padding: 14px 50px; background-position: 0 50%; background-size: 45px; overflow: hidden; }
  #ftNav .list .item {  float: left; margin-left: 55px; }
  #ftNav .list .item a {  font-size: 1.8rem; line-height: 2; } }

/*! #ftCr
-------------------------------------- */
#ftCr {  padding: 15px 0 28px; font-size: 1.2rem; text-align: center; line-height: 1.5; }

@media all and (min-width: 600px) {  #ftCr {  padding: 31px 0 25px; font-size: 1.3rem; line-height: 1.54; } }
