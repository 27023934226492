/*! #gHeader
================================================ */
#gHeader { 
	position: relative;
	background: #fff;
	z-index: 2001;
	.btnSearch { 
		position: relative;
		float: right;
		width: 50px;
		height: 50px;
		background: $blue;
		cursor: pointer;
		span { 
			display: block;
			position: absolute;
			left: 13px;
			content: '';
			width: 24px;
			@include trans;
			&:nth-child(1) { 
				top: 24px;
				height: 2px;
				background-color: #fff;
				opacity: 0;
			}
			&:nth-child(2) { 
				top: 13px;
				left: 13px;
				height: 24px;
				background-color: transparent;
				overflow: hidden;
				img { 
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					width: 24px;
					height: 24px;
					margin: auto;
				}
			}
		}
		&.open { 
			span { 
				&:nth-child(1) { 
					opacity: 1;
					transform: rotate(-315deg);
				}
				&:nth-child(2) { 
					top: 24px;
					height: 2px;
					background-color: #fff;
					transform: rotate(-405deg);
				}
			}
		}
	}
	.btnGnav { 
		position: relative;
		float: right;
		width: 50px;
		height: 50px;
		margin-left: 1px;
		background: $gray;
		cursor: pointer;
		span { 
			position: absolute;
			left: 13px;
			width: 24px;
			height: 2px;
			background: #fff;
			@include trans;
			&:nth-child(1) {  top: 11px; }
			&:nth-child(2) {  top: 17px; }
			&:nth-child(3) {  top: 23px; }
		}
		.txt { 
			display: block;
			position: absolute;
			top: 31px;
			left: 11px;
			width: 28px;
		}
		&.open { 
			span { 
				&:nth-child(1) {  top: 17px; transform: rotate(-315deg); }
				&:nth-child(2) {  opacity: 0; transform: rotate(-360deg); }
				&:nth-child(3) {  top: 17px; transform: rotate(-405deg); }
			}
		}
	}
}
/*! #hdMeta
-------------------------------------- */
#hdMeta { 
	float: left;
	width: 210px;
	a { 
		position: relative;
		display: block;
		padding: 7px 0 0 10px;
		.logo { 
			overflow: hidden;
			.mark { 
				display: block;
				float: left;
				width: 29px;
				margin-right: 4px;
			}
			.name { 
				display: block;
				float: left;
				width: 162px;
			}
		}
		.txt { 
			position: absolute;
			top: 26px;
			left: 43px;
			width: 160px;
			padding-top: 2px;
			border-top: 1px solid $black;
			font-size: 1rem;
			text-align: center;
			line-height: 1.1;
		}
	}
	@media all and (min-width: 600px) { 
		width: 516px;
		height: 140px;
		a { 
			padding: 15px 0 0 40px;
			text-decoration: none;
			.logo { 
				.mark { 
					width: 66px;
					margin-right: 8px;
				}
				.name { 
					width: 366px;
					padding-top: 39px;
				}
			}
			.txt { 
				position: static;
				width: 435px;
				margin-top: 7px;
				padding-top: 7px;
				font-size: 2rem;
				text-align: right;
			}
		}
	}
}
/*! #hdSearch
-------------------------------------- */
#hdSearch { 
	display: none;
	position: absolute;
	top: 50px;
	left: 0;
	width: 100%;
	padding: 35px 25px 25px;
	background: $blue;
	box-sizing: border-box;
	z-index: 2001;
	.tit { 
		display: block;
		margin-bottom: 10px;
		color: #fff;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1.6;
	}
	.inputWrap { 
		position: relative;
		input[type="search"] { 
			display: block;
			width: 100%;
			height: 45px;
			padding: 10px 55px 10px 10px;
			background: #fff;
			box-sizing: border-box;
		}
		input[type="submit"] { 
			position: absolute;
			top: 0;
			right: 10px;
			width: 45px;
			height: 45px;
			background: url(../img/common/ico_search01_blue.png) no-repeat 50% 50%;
			background-size: 25px;
			text-indent: 200%;
			white-space: nowrap;
			overflow: hidden;
		}
	}
	@media all and (min-width: 600px) { 
		display: block;
		position: absolute;
		top: 30px;
		left: auto;
		right: 40px;
		width: 466px;
		padding: 12px 14px;
		.tit { 
			float: left;
			width: 127px;
			margin-bottom: 0;
			padding-top: 5px;
		}
		.inputWrap { 
			float: right;
			width: 260px;
			padding-right: 51px;
			input[type="search"] { 
				display: block;
				width: 260px;
				height: 36px;
				padding: 10px;
				border-radius: 4px;
			}
			input[type="submit"] { 
				position: absolute;
				top: 0;
				right: 0;
				width: 36px;
				height: 36px;
				border: 2px solid #fff;
				border-radius: 4px;
				background-image: url(../img/common/ico_search01_white.png);
				background-size: 27px;
			}
		}
	}
}
/*! #hdContact
-------------------------------------- */
#hdContact { 
	@media all and (min-width: 600px) { 
		position: absolute;
		top: 108px;
		right: 40px;
		a { 
			display: block;
			padding-left: 42px;
			background: url(../../img/common/ico_mail01_blue.png) no-repeat 0 50%;
			color: $blue;
			font-size: 2rem;
			line-height: 1.6;
			text-decoration: underline;
			&:hover { 
				text-decoration: none;
			}
		}
	}
}

/*! #gNav
================================================ */
#gNav { 
	display: none;
	position: absolute;
	top: 50px;
	left: 0;
	width: 100%;
	padding: 25px 25px 35px;
	background: $sky;
	box-sizing: border-box;
	z-index: 2001;
	.listMain { 
		.item { 
			margin-bottom: 14px;
			padding-bottom: 14px;
			border-bottom: 1px solid $blue;
			.parent { 
				position: relative;
				display: block;
				padding: 9px 10px 8px;
				color: $black;
				font-size: 18px;
				font-weight: 700;
				line-height: 1.5;
				&::after { 
					position: absolute;
					top: 50%;
					right: 10px;
					width: 12px;
					height: 15px;
					margin-top: -7px;
					content: '';
					background: url(../img/common/ico_arrow01_blue.png) no-repeat;
					background-size: cover;
					transform: rotate(90deg);
				}
			}
			.itemChild { 
				a { 
					position: relative;
					display: block;
					padding: 2px 35px;
					color: $black;
					font-size: 1.5rem;
					font-weight: 700;
					line-height: 1.6;
					&::before { 
						position: absolute;
						top: 7px;
						left: 20px;
						width: 10px;
						height: 14px;
						content: '';
						background: url(../img/common/ico_arrow01_black.png) no-repeat;
						background-size: cover;
					}
				}
			}
		}
	}
	.listSub { 
		margin-bottom: 32px;
		.item { 
			a { 
				position: relative;
				display: block;
				padding: 6px 10px;
				font-size: 1.5rem;
				line-height: 1.6;
				&::after { 
					position: absolute;
					top: 50%;
					right: 10px;
					width: 12px;
					height: 15px;
					margin-top: -7px;
					content: '';
					background: url(../img/common/ico_arrow01_blue.png) no-repeat;
					background-size: cover;
				}
			}
		}
	}
	.btnClose { 
		width: 230px;
		height: 45px;
		margin: 0 auto;
		padding: 10px;
		border: 1px solid $blue;
		box-sizing: border-box;
		color: $blue;
		font-size: 1.6rem;
		line-height: 1.5;
		text-align: center;
		cursor: pointer;
	}
	@media all and (min-width: 600px) { 
		display: block;
		position: static;
		width: 100%;
		padding: 0;
		background: #fff;
		border-bottom: 2px solid $blue;
		.listMain { 
			width: 960px;
			margin: 0 auto;
			padding: 0 20px;
			.item { 
				position: relative;
				float: left;
				width: 230px;
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: 0;
				& + .item { 
					margin-left: 135px;
				}
				.parent { 
					position: relative;
					padding: 15px 0;
					font-size: 2.5rem;
					text-align: center;
					line-height: 1.6;
					@include trans;
					cursor: pointer;
					&::after { 
						display: none;
					}
				}
				.child { 
					position: absolute;
					top: 57px;
					left: 0;
					width: 100%;
					padding: 10px 0;
					background: rgba(220,255,255,.9);
					z-index: 2000;
					@include trans;
					visibility: hidden;
					opacity: 0;
					&::before { 
						position: absolute;
						top: -15px;
						left: 50%;
						width: 0;
						height: 0;
						content: '';
						margin-left: -9px;
						border-style: solid;
						border-width: 0 9px 15px 9px;
						border-color: transparent transparent $sky;
					}
				}
				.itemChild { 
					a { 
						position: relative;
						padding: 13px 0;
						font-size: 2rem;
						text-align: center;
						line-height: 1.5;
						@include trans;
						&:hover { 
							color: $blue;
							text-decoration: underline;
						}
						&::before { 
							display: none;
						}
					}
				}
				.parent.open { 
					background: $blue;
					color: #fff;
					& + .child { 
						top: 72px;
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
	}
}
/*! #overlay
-------------------------------------- */
#overlay { 
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(53,53,54,.55);
	z-index: 2000;
}