/*! #gFooter
================================================ */
#gFooter { 
	.footerIn { 
		padding-right: 15px;
		padding-left: 15px;
	}
	@media all and (min-width: 600px) { 
		.footerIn { 
			width: 960px;
			margin: 0 auto;
			padding-right: 20px;
			padding-left: 20px;
		}
	}
}
/*! #ftLink
-------------------------------------- */
#ftLink { 
	margin-top: -15px;
	padding-top: 13px;
	padding-bottom: 40px;
	border-bottom: 2px solid $blue;
	.bnr { 
		margin-top: 15px;
		a { 
			display: block;
			border: 2px solid #a0a0a0;
		}
	}
	@media all and (min-width: 600px) { 
		margin-top: 0;
		padding: 15px 0 0;
		text-align: center;
		letter-spacing: -.5em;
		.bnr { 
			display: inline-block;
			width: 360px;
			margin: 0 35px 70px;
			letter-spacing: normal;
			a { 
				@include trans;
				&:hover { 
					border-color: $blue;
				}
			}
		}
	}
}
/*! #ftNav
-------------------------------------- */
#ftNav { 
	position: relative;
	padding-top: 35px;
	.pagetop { 
		position: absolute;
		top: -23px;
		left: 50%;
		width: 46px;
		height: 46px;
		margin-left: -23px;
		z-index: 10;
		a { 
			position: relative;
			display: block;
			width: 46px;
			height: 46px;
			padding-top: 18px;
			border-radius: 23px;
			background: $blue;
			color: #fff;
			font-size: 1rem;
			text-align: center;
			box-sizing: border-box;
			&::before { 
				position: absolute;
				top: 8px;
				left: 50%;
				width: 8px;
				height: 10px;
				content: '';
				margin-left: -4px;
				background: url(../img/common/ico_arrow01_white.png) no-repeat 50% 50%;
				background-size: cover;
				transform: rotate(-90deg);
			}
		}
	}
	.list { 
		padding-top: 47px;
		background: url(../img/common/footer/logo01.png) no-repeat 50% 0;
		background-size: 38px;
		.item { 
			a { 
				display: block;
				color: $blue;
				font-size: 1.5rem;
				font-weight: 700;
				text-align: center;
			}
		}
	}
	@media all and (min-width: 600px) { 
		padding-top: 0;
		.pagetop { 
			position: absolute;
			top: -51px;
			right: -76px;
			left: auto;
			width: 64px;
			height: 64px;
			margin-left: 0;
			a { 
				width: 64px;
				height: 64px;
				padding-top: 26px;
				border-radius: 32px;
				font-size: 1.3rem;
				&::before { 
					top: 12px;
					width: 11px;
					height: 14px;
					margin-left: -7px;
					@include trans;
				}
				&:hover { 
					text-decoration: none;
					&::before { 
						top: 9px;
					}
				}
			}
		}
		.list { 
			padding: 14px 50px;
			background-position: 0 50%;
			background-size: 45px;
			overflow: hidden;
			.item { 
				float: left;
				margin-left: 55px;
				a { 
					font-size: 1.8rem;
					line-height: 2;
				}
			}
		}
	}
}
/*! #ftCr
-------------------------------------- */
#ftCr { 
	padding: 15px 0 28px;
	font-size: 1.2rem;
	text-align: center;
	line-height: 1.5;
	@media all and (min-width: 600px) { 
		padding: 31px 0 25px;
		font-size: 1.3rem;
		line-height: 1.54;
	}
}