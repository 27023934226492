@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  02_base
================================================ */
html { 
	overflow: auto;
}
body { 
	min-width: 320px;
	color: $black;
	font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-size: 1.3rem;
	font-weight: 400; //medium
	line-height: 1.92; //25px
	overflow: hidden;
	@media all and (min-width: 600px) { 
		min-width: 1000px;
	}
}
div, span,
h1, h2 , h3 , h4 , h5 , h6 ,
p , address , em , small , strong , sub , sup , b , i ,
dl , dt , dd , ol , ul , li ,
form , label , input , select , textarea ,
table , caption , tbody , tfoot , thead , tr , th , td ,
article , aside , footer , header ,nav , section , time { 
	font: inherit;
}
a { 
	color: $black;
	text-decoration: none;
	@media all and (min-width: 600px) { 
		&:hover { 
			text-decoration: underline;
		}
		&.alpha { 
			@include trans($prop: opacity);
			&:hover { 
				opacity: .8;
				text-decoration: none;
			}
		}
	}
}
img { 
	width: 100%;
	height: auto;
}
.cf:after { 
	display: block;
	clear: both;
	content: "";
}
.viewPc {  display: none; }
@media all and (min-width: 600px) { 
	.viewSp {  display: none; }
	.viewPc {  display: block; }
}
/*! media判定用
---------------------------------------- */
.mediaQuery { 
	display: none;
	font-family: "sp";
	@media all and (min-width: 600px) { 
		font-family: "pc";
	}
}

/* layout
--------------------------------------- */
#contents { 
}
#sidebar { 
}