/*=============================================================
z-index
	~1000	used by slick
	2000~	gnav
=============================================================*/
/*=============================================================
 01_setting
=============================================================*/
@import "01_setting/_var.scss";
@import "01_setting/_mixin.scss";
/*=============================================================
 02_base
=============================================================*/
@import "02_base/_base.scss";

/*=============================================================
 03_layout
=============================================================*/
@import "03_layout/_header.scss";
@import "03_layout/_mainimg.scss";
@import "03_layout/_breadcrumb.scss";
@import "03_layout/_sidebar.scss";
@import "03_layout/_footer.scss";

